import React from "react";
import styled from "styled-components";
import Layout from "../comps/reusable/Layout";
import {graphql} from "gatsby";
import HeroSection from "../comps/reusable/HeroSection";
import TermsSection from "../comps/terms-and-conditions/TermsSection";
import PreFooter from "../comps/reusable/Pre-footer";

const Terms = () => {

  return (
    <Layout>
      <HeroSection
        data={{
          imageName: "heroTerms.png",
          hasButtons: false,
        }}
        nameSpace="terms"
      />
      <TermsSection title="termsFirstTitle" desc="termsFirstDesc" nameSpace="terms"/>
      <TermsSection title="termsSecondTitle" desc="termsSecondDesc" nameSpace="terms"/>
      <PreFooter nameSpace="terms"/>
    </Layout>
  );
}

export default styled(Terms)``;

export const query = graphql`
query ($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`;